/* icon */
.hotspot-icon {
    position: fixed;
    cursor: pointer;
    z-index: 100;

    .dot {
        width: 25px;
        height: 25px;
        margin: -12.5px 0 0 -12.5px;
        border-radius: 50%;
        position: absolute;
        background-color: hsl(196deg 100% 82% / 30%);
        border: 2px solid var(--color-outline-focus);
        top: 50%;
        left: 50%;
    }

    .pulse {
        width: 25px;
        height: 25px;
        margin: -11.5px 0 0 -11.5px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: hsl(0deg 0% 100%);
        border-radius: 50%;
        border: 1px solid hsl(205deg 100% 70%);
        transform: scale(2.2);
        opacity: 0;
        animation: pulsate 5s ease-out 0.375s 5;
    }

    .bounce {
        animation: bounce 5s 5;

        .bounce-icon {
            position: absolute;
            left: -5px;
            bottom: 3px;
            transform: rotate(7deg);
            color: var(--color-outline-focus);
            font-size: 2.75em;
            font-weight: 600;
        }
    }
}

@keyframes pulsate {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    30%,
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0%,
    15%,
    100% {
        transform: translateY(0);
    }

    7.5% {
        transform: translateY(4px);
    }
}

/* popover */
.hotspot.overlay {
    z-index: 104;
    background-color: hsl(191deg 7% 20% / 15%);

    .hotspot-popover {
        position: fixed;
        width: 250px;
        text-align: left;
        box-shadow: 0 5px 10px hsl(223deg 4% 54% / 20%);
        border: 1px solid hsl(0deg 0% 80%);
        border-radius: 4px;

        /* arrows */
        &::after,
        &::before {
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &::after {
            border-width: 12px;
        }

        &::before {
            border-width: 13px;
        }

        &.arrow-top {
            &::before,
            &::after {
                bottom: 100%;
                right: 50%;
            }

            &::after {
                border-bottom-color: hsl(164deg 44% 47%);
                margin-right: -12px;
            }

            &::before {
                border-bottom-color: hsl(0deg 0% 80%);
                margin-right: -13px;
            }
        }

        &.arrow-left {
            &::before,
            &::after {
                right: 100%;
                top: 50%;
            }

            &::after {
                border-right-color: hsl(0deg 0% 100%);
                margin-top: -12px;
            }

            &::before {
                border-right-color: hsl(0deg 0% 80%);
                margin-top: -13px;
            }
        }

        &.arrow-bottom {
            &::before,
            &::after {
                top: 100%;
                right: 50%;
            }

            &::after {
                border-top-color: hsl(0deg 0% 100%);
                margin-right: -12px;
            }

            &::before {
                border-top-color: hsl(0deg 0% 80%);
                margin-right: -13px;
            }
        }

        &.arrow-right {
            &::before,
            &::after {
                left: 100%;
                top: 50%;
            }

            &::after {
                border-left-color: hsl(0deg 0% 100%);
                margin-top: -12px;
            }

            &::before {
                border-left-color: hsl(0deg 0% 80%);
                margin-top: -13px;
            }
        }
    }

    .hotspot-popover-top {
        padding: 0 15px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: hsl(164deg 44% 47%);
    }

    .hotspot-title {
        margin: 0;
        font-size: 1.15em;
        font-weight: 600;
        color: hsl(0deg 0% 100%);
    }

    .hotspot-popover-content {
        background-color: hsl(0deg 0% 100%);
        padding: 15px;
    }

    .hotspot-popover-bottom {
        background-color: hsl(0deg 0% 100%);
        height: 90px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .hotspot-img {
        position: absolute;
        bottom: 10px;
        left: 4px;
    }

    .hotspot-confirm {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
}

.hotspot-img {
    height: 83px;
}

.hotspot-confirm {
    max-width: 125px;
    max-height: 70px;
    border: none;
    font-size: 1.15em;
    font-weight: 600;
    color: hsl(0deg 0% 100%);
    background-color: hsl(164deg 44% 47%);
    border-radius: 4px;
    white-space: normal;
    padding: 7px 20px;
    outline: none;

    &:hover {
        background-color: hsl(164deg 44% 56%);
    }
}

/* individual icon z-indexing */
#hotspot_intro_streams_icon,
#hotspot_intro_topics_icon,
#hotspot_intro_gear_icon {
    z-index: 103;
}
