#typing_notifications {
    display: none;
    margin-left: 10px;
    font-style: italic;
    color: hsl(0deg 0% 53%);
}

#typing_notification_list {
    list-style: none;
    margin: 0;
}

@media (width < $xl_min) {
    #typing_notifications {
        margin-right: 7px;
    }
}

@media (width < $md_min) {
    #typing_notifications {
        margin-right: 7px;
        margin-left: 7px;
    }
}
