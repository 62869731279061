#message-edit-history {
    .message_top_line {
        float: right;
    }

    .date_row > span {
        display: flex;
        align-items: center;
        white-space: nowrap;

        &::before,
        &::after {
            width: 100%;
            margin: 0;
        }
    }

    .message_time {
        position: static;
    }

    .message_author {
        position: relative;
    }

    .author_details {
        display: block;
        font-size: 12px;
        padding: 1px;
        text-align: right;
    }

    .messagebox-content {
        padding: 0 10px;
    }

    .message_edit_history_content {
        .highlight_text_inserted {
            color: hsl(122deg 72% 30%);
            background-color: hsl(120deg 64% 95%);
        }

        .highlight_text_deleted {
            color: hsl(0deg 100% 50%);
            background-color: hsl(7deg 90% 92%);
            text-decoration: line-through;
            word-break: break-all;
        }
    }
}
