/* Width of emoji used by user to display status. */
$user_status_emoji_width: 24px;

.right-sidebar {
    & a:hover {
        text-decoration: none;
    }
}

.right-sidebar-title {
    color: var(--color-text-sidebar-heading);
    font-size: inherit;
    font-weight: normal;
    display: inline;
}

#buddy_list_wrapper {
    position: relative;
    margin-left: 0;
    overflow: auto;
}

#buddy-list-users-matching-view {
    max-width: 95%;
    overflow-x: hidden;
    list-style-position: inside; /* Draw the bullets inside our box */

    & li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        list-style-type: none;
        border-radius: 4px;
        padding-right: 15px;
        padding-top: 1px;
        padding-bottom: 2px;

        .user-list-sidebar-menu-icon {
            position: absolute;
            top: 1px;
            right: 0;
            font-size: 1em;
            display: none;
            text-align: center;
            padding: 0 6px;

            & i {
                padding-right: 0.35em;
                display: inline-block;
                width: 13px;
                font-size: 17px;
                vertical-align: middle;
            }

            /*
            Hover does not work for touch-based devices like mobile phones.
            Hence the the icons does not appear, making the user unaware of its
            presence on such devices. The following media property displays the
            icon by default for such behaviour.
            */

            @media (hover: none) {
                display: inline;
                /* Show dots on touchscreens in a less distracting,
                   lighter shade. */
                color: var(--color-vdots-hint);
            }
        }

        &:hover {
            .user-list-sidebar-menu-icon {
                display: inline;
                cursor: pointer;
                color: var(--color-vdots-visible);

                &:hover {
                    color: var(--color-vdots-hover);
                }
            }
        }

        &:hover,
        &.highlighted_user {
            background-color: hsl(120deg 12.3% 71.4% / 38%);
        }
    }

    .user_circle {
        min-width: 8px;
        height: 8px;
        margin: 0 5px;
        display: block;
    }

    .empty-list-message {
        font-size: 1.2em;

        &:hover {
            background-color: inherit;
        }
    }

    & a {
        color: inherit;
        margin-left: 0;
    }
}

.user-presence-link,
.user_sidebar_entry .selectable_sidebar_block {
    display: flex;
    overflow: hidden;

    .user-name {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry .selectable_sidebar_block {
    width: calc(100% - 16px);
    display: flex;
    flex-direction: row;
}

.user-name-and-status-wrapper {
    display: block;
    width: 100%;
}

.user-presence-link {
    width: calc(100% - $user_status_emoji_width);

    .status-emoji {
        top: 9px;
    }
}

.my_user_status {
    opacity: 0.5;
}

.selectable_sidebar_block {
    cursor: pointer;
}

.user_list_style_values {
    .user-name-and-status-emoji {
        display: flex;
        align-items: center;
        width: 100%;

        .user-name {
            display: inline-block;
            max-width: calc(100% - $user_status_emoji_width);
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .status-emoji {
            line-height: 20px;
            margin-left: 6px;
        }
    }

    .status-text {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.user_sidebar_entry {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .user-name-and-status-emoji {
        display: flex;
    }

    .status-text {
        display: block;
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.75;
        font-size: 90%;
    }

    & span.status-text:not(:empty) {
        margin-top: -3px;
    }

    .unread_count {
        display: none;
        margin-top: 2.5px;
    }

    &.user-with-count .unread_count {
        display: block;
        margin-left: 4px;

        &:empty {
            /* Hide otherwise empty unread count pill
               after DMs have been read, but before the
               count has been removed from the DOM. */
            display: none;
        }
    }
}

#userlist-toggle {
    display: none;
    text-align: center;
    vertical-align: middle;
}

#userlist-toggle-button {
    text-decoration: none;
    color: hsl(0deg 0% 60%);

    &:hover {
        color: inherit;
    }
}

.right-sidebar-items:first-of-type #userlist-header {
    border-top: none;
}

#userlist-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

    #userlist-title {
        margin: 0;
    }

    #user_filter_icon {
        opacity: 0.5;
        margin-right: 5px;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    /* hovering over the userlist-header creates the same highlight effect as hovering over the user_filter_icon */
    &:hover > #user_filter_icon {
        opacity: 1;
        cursor: pointer;
    }
}

.right-sidebar-shortcuts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

@media (width < $xl_min) {
    #userlist-toggle {
        display: block;
    }
}

#user_search_section {
    white-space: nowrap;
    margin-bottom: 10px;

    & input {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
        width: calc(100% - 40px - 3px);
    }

    .clear_search_button {
        margin-left: -1px;
    }
}

@media (width < $sm_min) {
    #userlist-toggle {
        height: 30px;
        line-height: 30px;
    }

    #userlist-toggle-button {
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.right-sidebar-shortcuts .realm-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    width: 230px;

    .color_animated_button {
        width: 100%;
        margin-bottom: 10px;
    }

    & hr {
        width: 90%;
    }
}
