#scheduled_messages_overlay_container {
    .scheduled-messages-loading {
        margin-top: 10px;
        display: grid;

        > * {
            grid-row-start: 1;
            grid-column-start: 1;
            margin: auto;
        }
    }

    .no-overlay-messages {
        display: none;

        &:only-child {
            display: block;
        }
    }
}

#scheduled_message_indicator {
    display: block;
    margin-left: 10px;
    font-style: italic;
    color: hsl(0deg 0% 53%);
}

@media (width < $xl_min) {
    #scheduled_message_indicator {
        margin-right: 7px;
    }
}

@media (width < $md_min) {
    #scheduled_message_indicator {
        margin-right: 7px;
        margin-left: 7px;
    }
}
